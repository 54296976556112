const home = {
  home_title: "<span>Supercharge</span> Your Workforce",
  home_title_desc:
    "<b>Atendi</b> adalah solusi lengkap untuk mengelola karyawan Anda, mulai dari jadwal kerja, absensi, dan aktivitas lainnya dengan mudah",
  contact_us: "Jadwalkan Demo",
  home_workforce_need: "Aplikasi <span>Canggih</span> untuk karyawan Anda",
  home_reduce: "<span>Mengurangi </span> Kekacauan, Biaya, & Turnover",
  home_reduce1: "Buat <b>Jadwal</b> Karyawan <b>lebih cepat</b>",
  home_reduce2:
    "<b>Lacak Kehadiran </b> karyawan lebih <b>akurat</b> dan  <b>mudah </b>",
  home_reduce3: "Buat semua orang <b>selaras</b> dan <b>terinformasi</b>",
  home_read_more: "Lebih Lanjut",
  home_better: "<span>Transformasikan</span> karyawan Anda",
  home_better1: "Meningkatkan <b>efisiensi</b> kerja",
  home_better2:
    "Menghilangkan berbagai <b>alasan</b> & meningkatkan <b>accountabilitas</b>",
  home_better3: "Dibangun untuk organisasi <b>berapapun jumlahnya</b>",
  home_solution1: "Solusi cerdas",
  home_solution2: "<span>bertumbuh</span> bersama karyawan Anda",
  start_using_atendi: "Gunakan Atendi Sekarang!",
  our_clients: "<span>Klien</span> Kami",
};

const footer = {
  footer_title: "<span>Hubungi & Follow </span> Kami",
};

const header = {
  benefits: "Keuntungan?",
  features: "Fitur Produk",
  use_case: "Penggunaan",
  pricing: "Harga",
};

const benefits = {
  benefits_title: "<span>Manfaat & Dampak Nyata</span> Atendi Untuk Anda",
  benefits_manage: "Jadwalkan karyawan Anda dengan <span>mudah</span>",
  benefits_manage_desc:
    "<b>Atendi</b> dapat memenuhi semua kebutuhan penjadwalan Anda. Baik itu <i>jadwal tetap</i>, <i>fleksibel</i> atau <i>shift</i>, <b>Atendi</b> dapat mengatasinya. Mengajukan dan menyetujui <i>cuti</i> dari ponsel, menyederhanakan proses yang kompleks.",
  benefits_increase: "Meningkatkan <span>engagement</span>",
  benefits_increase_desc:
    "<b>Atendi</b> percaya bahwa informasi yang selaras adalah salah satu kunci untuk eksekusi yang sukses. Informasi yang tepat waktu dan akurat juga membantu penyelarasan karyawan. Oleh karena itu, <b>Atendi</b> hadir dengan fitur <b>Buletin</b> dan <b>Berita</b> sehingga pihak manajemen dapat menyebarkan informasi berkualitas kepada karyawan dan melacaknya.",
  benefits_build: "Memecahkan masalah <span>nyata</span>",
  benefits_build_desc:
    "<b>Atendi</b> hadir dengan tools yang ampuh untuk menyelesaikan berbagai hal. <b>Form kustom</b> memecahkan sebagian besar masalah pengambilan data Anda di lapangan. <b>Melacak</b> karyawan Anda dengan fitur <b>Ping</b>. Fitur <b>Sales</b> (coming soon) yang memberdayakan staf penjualan lapangan Anda. Masih banyak lagi!",
  benefits_reduce: "<span>Berkolaborasi</span> dengan tools lainnya",
  benefits_reduce_desc:
    "Tambahkan <b>Atendi</b> dalam mesin bisnis Anda dengan fitur <b>Integrasi</b>, yang menghubungkan <b>Atendi</b> ke sistem Anda, tanpa atau dengan pemrograman yang relatif sederhana. <b>Atendi</b> juga dapat diintegrasikan dengan aplikasi eksternal seperti Google Spreadsheet dan lainnya.",
  benefits_happy: "Fokus pada <span>bisnis Anda</span>",
  benefits_happy_desc:
    "<b>Atendi</b>, tidak seperti aplikasi HR(Human resource) pada umumnya, tidak hanya untuk penjadwalan, absensi, dan cuti. Ini adalah <b>tool bisnis</b> dengan serangkaian fitur yang bertujuan untuk membantu karyawan Anda menjadi sangat produktif. Ini dapat membantu meningkatkan proses bisnis Anda, tidak hanya mengurangi pekerjaan rumah.",
};

const features = {
  features_title: "<span>Atendi, </span> Managemen Tim yang Lebih Baik",
  features_schedule: "Penjadwalan Karyawan",
  features_schedule_desc:
    "Mengatur jadwal karyawan <b>mudah</b> dengan interface website kami. <b>Atendi </b> memenuhi sebagian besar kebutuhan penjadwalan Anda",
  features_schedule1: "Tipe Jadwal",
  features_schedule1_desc:
    "Mendukung pengaturan jadwal tipe tetap, shift, bahkan fleksibel. Pertukaran jadwal, perhitungan lembur, & banyak lagi",
  features_schedule2: "Pengaturan Lokasi",
  features_schedule2_desc:
    'Tentukan lokasi dimana karyawan dapat Check-in & Check-out. Tentukan lokasi visit staff penjualan. Untuk pekerja remote, atur lokasi dengan tipe "Dimana saja"',
  features_schedule3: "Jadwal Grup",
  features_schedule3_desc:
    "Atur karyawan yang memiliki jadwal yang sama, buat grup agar proses penjadwalan makin cepat",
  features_attendance: "Kehadiran",
  features_attendance_desc:
    "Sistem Absensi Online dengan ponsel, Tanpa investasi alat yang mahal",
  features_attendance1: "Absen dengan Mobile apps",
  features_attendance1_desc:
    "Absensi online, Visit pelanggan, mengajukan cuti langsung dari perangkat ponsel, Android ataupun iOS. Dengan design yang elegan.",
  features_attendance2: "Laporan realtime",
  features_attendance2_desc:
    "Dengan sistem penilaian fitur Vibe, Anda akan mendapatkan laporan kehadiran secara cepat & realtime.",
  features_attendance3: "Sistem Cuti Otomatis",
  features_attendance3_desc:
    "Pengajuan & persetujuan cuti karyawan beserta penggantinya langsung dari ponsel, praktis & simpel.",
  features_microapp: "Micro App",
  features_microapp_desc:
    "<b>Micro App</b> memungkinkan Anda membuat formulir kustom untuk memenuhi kebutuhan operasional bisnis harian Anda sepeti Checklist tugas, laporan penjualan, laporan sisa stok, reimbursement & banyak lagi",
  features_microapp_desc1:
    "<b>Micro App</b> memungkinkan Anda membuat formulir kustom untuk memenuhi kebutuhan operasional bisnis harian Anda",
  features_microapp_desc2:
    "sepeti Checklist tugas, laporan penjualan, laporan sisa stok, reimbursement & banyak lagi",
  features_microapp1: "Form kustom",
  features_microapp1_desc:
    "Buat form untuk segala keperluan bisnismu dengan beberapa langkah mudah & cepat mengunakan form builder kami",
  features_microapp2: "Kaitkan form ke aktifitas",
  features_microapp2_desc:
    "Anda dapat mengatur karyawan untuk mengisi form sebelum aktivitas seperti checkin, visit ataukah checkout.",
  features_microapp3: "Analitik & Integrasi",
  features_microapp3_desc:
    "Manfaatkan form analitik kami sebagai bahan analisa membuat keputusan yang tepat. Form juga dapat diintegrasikan dengan sistem Anda.",
  features_content: "Content Sharing",
  features_content_desc:
    "Berikan <b>informasi</b> & <b>update</b> terbaru kepada para karyawan. Managemen informasi yang terpusat, Tidak ada lagi email atau teks WhatsApp yang hilang.",
  features_content_desc1:
    "Berikan <b>informasi</b> & <b>update</b> terbaru kepada para karyawan. ",
  features_content_desc2:
    "Managemen informasi yang terpusat, Tidak ada lagi email atau teks WhatsApp yang hilang. ",
  features_content1: "Buat buletin atau berita",
  features_content1_desc:
    "Editor yang mudah digunakan untuk menulis pesan penting kepada para karyawan.",
  features_content2: "Pesan yang tertarget",
  features_content2_desc:
    "Publikasikan buletin atau berita ke semua karyawan atau kelompok tertentu. Atau Anda dapat mempublikasikan pada tanggal tertentu.",
  features_content3: "Konten Engagement",
  features_content3_desc:
    "Laporan untuk memeriksa siapa yang telah membaca buletin atau berita. Ini untuk memastikan bahwa informasi penting telah tersampaikan.",
  features_integration: "Siap untuk Integrasi",
  features_integration_desc:
    "<b>Atendi</b> tidak dimaksudkan sebagai sistem yang berdiri sendiri. <b>Atendi</b> dapat diintegrasikan ke dalam sistem bisnis Anda saat ini.",
  features_integration1: "Integrasi secara real time",
  features_integration1_desc:
    "Dengan teknologi PUSH API, integrasi dapat dilakukan dengan real time & sangat mudah",
  features_integration2: "ERP, Google apps, apapun",
  features_integration2_desc:
    "Segala aktivitas dalam sistem kami dapat diintegrasikan ke dengan sistem ERP atau HRIS Anda.",
  features_integration3: "Integrasi dengan apapun",
  features_integration3_desc:
    "Atendi dapat diintegrasikan dengan beberapa apps popular seperti Google spreadsheed & lainnya (segera launching)",
};

const pricing = {
  pricing_title: "<span>Hubungi kami </span> untuk penawaran spesial",
  pricing_user_free: "Coba <b>GRATIS 10 PENGGUNA</b> selama setahun",
  pricing_contact: "Hubungi kami",
};

const contactus = {
  book_and_follow: "Jadwalkan <span>Demo</span>",
  book_demo: "Jadwalkan <span>Demo</span>",
  book_demo_desc:
    "Silakan melakukan registrasi dan akan kami hubungi balik secepatnya.",
  order_demo: "Jadwalkan Demo untuk Saya",
};

const usecase = {
  usecase_title: "Satu produk untuk beberapa kasus berbeda",
  usecase_learn_other: "Pelajari Kasus Lain",
  usecase_office_title: "Atendi untuk <span>Perkantoran</span>",
  usecase_office_desc1:
    "<b>Atendi</b> bekerja sangat baik dalam single kantor atau juga kantor dengan banyak cabang. Karyawan dapat mengambil absensi secara online dengan perangkat seluler mereka sendiri di lokasi kantor yang ditentukan. Jadwal kerja dapat diatur sesuai dengan kebijakan masing-masing kantor dan peran karyawan, baik jam 8 pagi hingga 5 sore, atau jam kerja fleksibel dimana waktu mulai dapat bervariasi.    ",
  usecase_office_desc2:
    "Selalu berikan karyawan informasi terbaru di lokasi cabang dengan <b>Atendi Bulletin.</b> Atau perbarui mereka sesering mungkin dengan berita yang relevan melalui <b>Atendi News</b>. Anda juga dapat memastikan karyawan Anda membaca informasi tersebut dengan analisis konten kami.   ",
  usecase_office_desc3:
    "Mengajukan cuti di kantor cabang itu mudah. Fitur <b>Atendi Leave</b> menyediakan aplikasi permintaan cuti yang mudah melalui ponsel. Demikian juga, menyetujui cuti dapat dilakukan melalui ponsel juga.   ",
  usecase_office_thumbnail: "Perkantoran",
  usecase_office_thumbnail_desc:
    "Lacak kehadiran karyawan secara real time di kantor cabang di seluruh lokasi.",
  usecase_outsource_title: "Atendi untuk <span>Bisnis Outsourcing</span>",
  usecase_outsource_desc1: `<b>Atendi</b> lahir dari bisnis outsourcing. Kami memahami tantangan mengelola sejumlah besar karyawan. <b>Atendi</b> mampu menangani berapapun jumlah karyawan Anda, ratusan hingga ribuan.
    {br}{br} Beberapa karyawan mungkin memerlukan jadwal tetap, sementara yang lain mungkin memerlukan jenis jadwal shift dengan berbagai lokasi yang ditugaskan, <b>Atendi</b> dapat mengatasinya. Pantau keberadaan mereka dengan <b>Atendi Ping</b>.
    `,
  usecase_outsource_desc2:
    "Mengirimkan pengumuman ke sekelompok besar orang tidaklah mudah. Umumkan informasi penting dengan <b>Buletin Atendi</b> dan lacak seberapa baik buletin dibaca.    ",
  usecase_outsource_desc3:
    "Untuk karyawan di bisnis retail, mengumpulkan informasi seperti market intelligent, laporan planograms, survei pelanggan, atau bahkan pemesanan untuk <i>sell-in</i> bisa dilakukan dengan <b>Atendi Micro App.</b>",
  usecase_outsource_desc4:
    "Periksa keberadaan karyawan selama jam kerja dengan <b>Atendi Ping</b>. Ini untuk memastikan bahwa karyawan tersebut sesuai dengan jadwal dan lokasi yang ditetapkan.",
  usecase_outsource_thumbnail: "Outsource",
  usecase_outsource_thumbnail_desc:
    "Tantangan bisnis outsourcing adalah melacak sejumlah besar karyawan mereka di lokasi terdistribusi.",
  usecase_cafe_title: "Atendi untuk Industri <span>Hospitality</span>",
  usecase_cafe_desc1:
    "Mengelola shift untuk karyawan kafe, restoran, atau hotel memang menantang. Selain tantangan penjadwalan, ada juga kebutuhan untuk mencari pengganti ketika seseorang sedang cuti. <b>Atendi</b> memiliki jadwal manajemen shift dengan pelaporan <i>timesheet</i>.    ",
  usecase_cafe_desc2:
    "Karyawan yang sedang mengajukan cuti dapat memilih calon pengganti untuk disetujui oleh atasan. Meminimalkan kompleksitas penjadwalan.    ",
  usecase_cafe_desc3:
    "Gunakan <b>Atendi Bulletin</b> untuk menyebarkan informasi kepada karyawan tentang ketersediaan menu, produk baru, dan promosi.    ",
  usecase_cafe_thumbnail: "Cafe & Restoran",
  usecase_cafe_thumbnail_desc:
    "Memenuhi tantangan mengelola shift untuk karyawan kafe, restoran, dan hotel.",
  usecase_spa_title: "Tim penjualan yang <span>produktif</span> dengan Atendi",
  usecase_spa_desc1:
    "Lacak kunjungan tim penjualan ke calon prospek atau lokasi pelanggan. Fitur <b>Atendi visit</b> dapat melakukan pencatatan kunjungan & karyawan wajib menulis laporan sebelum meninggalkan tempat.",
  usecase_spa_desc2:
    "<b>Formulir Atendi</b> dapat digunakan untuk membuat formulir pemesanan kustom sesuai dengan kebutuhan Anda. Atau, buat survei pelanggan, pengembalian produk, dan lainnya.",
  usecase_spa_desc3:
    "Baik fitur <b>Integrasi Atendi</b> dan <b>Formulir Atendi</b> dapat digunakan untuk membuat <b>Micro app</b>. Jadi, selama kunjungan pelanggan, staf penjualan perlu mengirimkan pesanan yang akan masuk ke ERP Anda? Bisa diselesaikan. Dengan integrasi Atendi, formulir, dan mungkin sedikit pemrograman - Anda dapat memiliki aplikasi Anda sendiri di perangkat seluler.    ",
  usecase_spa_thumbnail: "Tenaga penjualan",
  usecase_spa_thumbnail_desc:
    "Buat tim penjualan Anda menjadi lebih produktif dengan melacak kunjungan mereka ke tempat pelanggan.",
  usecase_manufacture_title:
    "Absensi Online <span>Contactless</span> dengan Atendi",
  usecase_manufacture_desc1:
    "Jadwalkan karyawan dalam jumlah grup besar, mengelola shift mereka, dan melacak kehadiran mereka. Pelaporan </i>timesheet</i> bulanan sebagai <i>spreadsheet Excel.</i>    ",
  usecase_manufacture_desc2:
    "Lacak dan setujui cuti melalui ponsel. Tetapkan kebijakan cuti yang sesuai dengan pemerintah dan hari libur. Dengan fitur <b>Atendi Vibe</b>, Anda dapat dengan cepat melihat bagaimana keseluruhan kinerja kehadiran pada hari itu.",
  usecase_manufacture_desc3:
    "Buat formulir khusus dengan fitur <b>Form Atendi</b> untuk menggantikan kertas. Gunakan fitur <b>Buletin Atendi</b> untuk menyebarkan informasi penting kepada semua atau kelompok karyawan.    ",
  usecase_manufacture_thumbnail: "Manufaktur",
  usecase_manufacture_thumbnail_desc:
    "Ganti mesin pencatatan jam kerja atau absensi sidik jari Anda. Hemat biaya, dan tanpa kontak.",
  usecase_remote_title: "Atendi untuk <span>Pekerja Remote</span>",
  usecase_remote_desc1:
    "Jadwalkan pekerja jarak jauh Anda sesuai dengan kebutuhan perusahaan Anda. Apakah pekerja remote harus berada di lokasi tertentu atau di mana saja? Haruskah jam kerja ditetapkan, fleksibel atau bahkan kapan saja? <b>Atendi</b> bisa mengatasinya. <i>Timesheet</i> dapat dibuat sebagai <i>Excel Spreadsheet.</i>    ",
  usecase_remote_desc2:
    "Jaga agar informasi penting disebarluaskan ke seluruh pekerja jarak jauh dengan fitur <b>Buletin dan News</b>. Buat semua orang di memiliki informasi yang sama. Tidak ada lagi teks atau email WhatsApp yang hilang.    ",
  usecase_remote_desc3:
    "Jika Anda perlu melacak lokasi karyawan jarak jauh selama jam kerja, fitur <b>Atendi Ping</b> dapat digunakan. <i>Ping</i> dirancang untuk bekerja hanya selama jam kerja untuk memastikan privasi karyawan di luar jam kerja.    ",
  usecase_remote_thumbnail: "Pekerja Remote",
  usecase_remote_thumbnail_desc:
    "Kerja dari rumah? Atau di mana saja dari jarak jauh? Jadwal tetap atau kapan saja? Kami bisa mengatasinya.",
};

const freemium = {
  choose_your_plan: "<span>Schema</span> harga yang simple",
};

export default {
  ...home,
  ...footer,
  ...header,
  ...benefits,
  ...features,
  ...contactus,
  ...pricing,
  ...usecase,
  ...freemium,
};
