const home = {
  home_title: "<span>Supercharge</span> Your Workforce",
  home_title_desc:
    "<b>Atendi</b> is complete solution to manage your workforce, from work schedules, attendance, and other activities with ease.",
  contact_us: "REQUEST DEMO",
  home_workforce_need: "<span>Powerful Tools</span> For Your Workforce",
  home_reduce: "<span>Manage</span> Chaos, Reduce Cost & Turnover",
  home_reduce1: "Create employees schedules <b>in minutes</b>",
  home_reduce2: "<b>Track attendance</b> accurately and with ease",
  home_reduce3: "Keep everybody <b>aligned</b> and <b>informed</b>",
  home_read_more: "Read More",
  home_better: "<span>Transform</span> Your Workforce",
  home_better1: "Increase <b>efficiencies</b>",
  home_better2: "Eliminate <b>excuses</b> & improve <b>accountability</b> ",
  home_better3: "Built for organizations of <b>any size</b> ",
  home_solution1: "Smart solutions that",
  home_solution2: "<span>grow</span> with your workforce",
  start_using_atendi: "Start Using Atendi Now!",
  our_clients: "Our <span>Happy</span> Clients",
};

const footer = {
  footer_title: "<span>Contact or Follow </span> Us",
};

const header = {
  benefits: "Why Atendi?",
  features: "Product Tour",
  use_case: "Use Cases",
  pricing: "Pricing",
};

const benefits = {
  benefits_title: "<span>Real Benefits, Real Impact</span> How Atendi Can Help",
  benefits_manage: "Schedule your workforce with <span>ease</span>",
  benefits_manage_desc:
    "<b>Atendi</b> can cater to all your scheduling needs. Be it <i>fixed</i>, <i>flexible</i> or <i>shifts</i> schedule, <b>Atendi</b> can handle it. Applying and approving <i>leaves</i> from mobile, remove much of the chores.",
  benefits_increase: "<span>Improve engagement</span>",
  benefits_increase_desc:
    "<b>Atendi</b> believes that synchronized information is one of the keys to successful executions. Timely and accurate information also helps with employees alignment. <b>Atendi</b>, therefore, comes with <b>Bulletin</b> and <b>News</b> system so that the management can disperse quality information to the workforce and track them with analytics.",
  benefits_build: "<span>Solve real problems</span>",
  benefits_build_desc:
    "<b>Atendi</b> comes with powerful tools to get things done. <b>Customizable forms</b> solve most of your data capture problems in the fields. <b>Locate</b> your employee with <b>Ping</b>. Our up and coming <b>Sales</b> system to empower your field sales people. Many more!",
  benefits_reduce: "<span>Works well</span> with others",
  benefits_reduce_desc:
    "Put <b>Atendi</b> in your business machineries with its <b>Integration</b> feature. Integration feature connects <b>Atendi</b> to your existing system with no or relatively simple programming. <b>Atendi</b> can also be integrated with external apps such as Google Spreadsheet and others.",
  benefits_happy: "Focus on <span>your business</span>",
  benefits_happy_desc:
    "<b>Atendi</b>, unlike typical Human Resource apps in the market, is not just for scheduling, taking attendance, and applying leaves. It is a <b>business tool</b> with sets of features that aims to help your workforce to be very productive. It can help to improve your business process, not just reducing chores.",
};

const features = {
  features_title: "<span>Atendi, </span> Workforce Management Simplified",
  features_schedule: "Employee Scheduling",
  features_schedule_desc:
    "Creating schedule is easy with our web interface. <b>Atendi</b> covers most of your scheduling needs.",
  features_schedule1: "Schedule types",
  features_schedule1_desc:
    "Supports fixed, flexible and any hour. Manage shifts easily. Swap schedule with authorization, overtime calculation, many more.",
  features_schedule2: "Location constraints",
  features_schedule2_desc:
    'Specify the locations where employee can check-in and check-out. Specify locations where customer visits are authorized. For remote worker, just set location to "anywhere"',
  features_schedule3: "Group schedule",
  features_schedule3_desc:
    "Assigns schedule to a group instead of individual employee. Save time.",
  features_attendance: "Attendance",
  features_attendance_desc:
    "Take attendance from mobile. No specialized attendance taking hardware investment.",
  features_attendance1: "Mobile attendance",
  features_attendance1_desc:
    "Check-in and check-out, capture visits to customers, apply leaves - all from mobile device. We support both Android and iOS. Clean and nice looking user interface.",
  features_attendance2: "Real time reporting",
  features_attendance2_desc:
    "Real time reporting on attendance. Using Atendi Vibe scoring system, you can quickly check the attendance situation.",
  features_attendance3: "Apply and approve leave, find a replacement",
  features_attendance3_desc:
    "Apply or approve leave from the mobile. Finding a replacement or approving replacement can be done on the mobile also.",
  features_microapp: "Micro App",
  features_microapp_desc:
    "<b>Micro App</b> allows you to create custom form to meet your daily business operational needs such as task checklist, sales report, inventory check, reimbursement, or anything that requires data capture.",
  features_microapp_desc1:
    "<b>Micro App</b> allows you to create custom form to meet your daily business operational needs",
  features_microapp_desc2:
    "such as task checklist, sales report, inventory check, reimbursement, or anything that requires data capture.",
  features_microapp1: "Custom form creation",
  features_microapp1_desc:
    "Create form that is suitable for your needs with our form builder.",
  features_microapp2: "Attached the form to activities",
  features_microapp2_desc:
    "You can let employee fill a form, say, before check out. Or, write a report before leaving customer's premise.",
  features_microapp3: "Analytics and Integration",
  features_microapp3_desc:
    "Use our analytics to gain insight on the form data. Form can be integrated with your existing system also. Would you like a customer order to go into your ERP directly? Micro App can help.",
  features_content: "Bulletin and News",
  features_content_desc:
    "Keep everybody <b>informed</b> and <b>updated</b> with the latest information. No more missing email or WhatsApp text. Crucial information is centralized.",
  features_content_desc1:
    "Keep everybody <b>informed</b> and <b>updated</b> with the latest information.",
  features_content_desc2:
    "No more missing email or WhatsApp text. Crucial information is centralized.",
  features_content1: "Create bulletin or news",
  features_content1_desc:
    "Easy to use editor allows to write important message to your employees easily.",
  features_content2: "Targeted publishing",
  features_content2_desc:
    "Publish bulletin or news to all employees or a select group. Or you can publish at specific date.",
  features_content3: "Ensure engagement",
  features_content3_desc:
    "Analytics to check who has read the bulletins or news. This is to ensure that important information has been consumed.",
  features_integration: "Ready for Integration",
  features_integration_desc:
    "<b>Atendi</b> is not meant to be a standalone system. It can be integrated into your current business system.",
  features_integration1: "Real-Time Integration",
  features_integration1_desc:
    "Atendi Push API makes it easy to integrate to any system",
  features_integration2: "Your ERP, Google App, anything",
  features_integration2_desc:
    "Any activities such as check-in can be automatically push to your ERP or HRIS system.",
  features_integration3: "Integrate to Anything",
  features_integration3_desc:
    "Atendi can be integrated with popular apps also such as Google Spreadsheet and others (coming soon)",
};

const pricing = {
  pricing_title: "<span>Contact us </span> for pricing",
  pricing_user_free: "Try our <b>10 FREE</b> users for a year",
  pricing_contact: "Contact us",
};

const contactus = {
  book_demo: "Book <span>Demo</span>",
  book_demo_desc: "Please register and we will contact you shortly.",
  order_demo: "Shedule My Demo",
};

const usecase = {
  usecase_title: "See how Atendi can fit into your company",
  // 'usecase_learn_other': 'Learn Other Use Case',
  usecase_office_title: " <span>Office and Branches</span>",
  usecase_office_desc1: `Atendi works great in a single office setting or distributed offices. Employees can take attendance online with their own mobile devices in their assigned office locations.

    Work schedule can be set according to the policies of each office and employees roles, whether a fixed 8 AM to 5 PM, or flexible office hours where start time may vary.`,
  usecase_office_desc2:
    "Keep employees in distributed location up to date with <b>Atendi Bulletin</b>. Or update them often with relevant news through <b>Atendi News</b>. You can also ensure your employees read those information with our content analytics.",
  usecase_office_desc3:
    "Applying for leave in distributed offices are easy. <b>Atendi</b> Leave feature provides easy leave request application via mobile. Likewise approving leave can be done via mobile also.",
  usecase_office_thumbnail: "Offices and Branches",
  usecase_office_thumbnail_desc:
    "Track in real time employee attendance in distributed offices across geography.",

  usecase_outsource_title:
    "Atendi is great for <span>Outsourcing Business</span>",
  usecase_outsource_desc1: `Atendi was born out of outsourcing business. We understand the challenge of managing large number of employees. Atendi scales well with any size of employees. From a few to tens of thousands.

    {br}{br} Some employees may need fixed schedule, while others may need shift type of schedule with various assigned locations, Atendi can handle that. Monitor their whereabout with <b>Atendi Ping</b>.`,

  usecase_outsource_desc2:
    "Sending announcements to large group of people are not easy. Announce important information with <b>Atendi Bulletin</b> and track how well the bulletins are read.",
  usecase_outsource_desc3: `For employees in retail business, collecting information such as market intelligent, planograms report, customer survey, or even ordering for sell-in can be done with <b>Atendi Micro App</b>.`,
  usecase_outsource_desc4: `Check employee's whereabout during working hours with <b>Atendi Ping</b>. This is to ensure that the employee is compliant with the assigned schedule and location.`,
  usecase_outsource_thumbnail: "Outsourcing Company",
  usecase_outsource_thumbnail_desc:
    "Outsourcing business real challenge is tracking their large number of employees in distributed locations.",

  usecase_cafe_title: "Atendi is for <span>Hospitality</span> industry",
  usecase_cafe_desc1: `Managing shifts for cafe, restaurant or hotel employees are challenging. Besides scheduling challenge, there is also a need for finding replacement when one is on leave. <b>Atendi</b> has shift management schedule with timesheet reporting.`,
  usecase_cafe_desc2: `Employee who is requesting leave can elect replacement candidates to be approved by the supervisor. Minimizing scheduling complexity.`,
  usecase_cafe_desc3:
    "Use <b>Atendi Bulletin</b> to disseminate information to employees about menu availability, new products, and promotions.",
  usecase_cafe_thumbnail: "Hospitality",
  usecase_cafe_thumbnail_desc:
    "Meeting the challenge of managing shifts for cafe, restaurant and hotel employees.",

  usecase_spa_title: "<span>Prolific</span> sales team with Atendi",
  usecase_spa_desc1: `Track sales team visits to potential leads or customer locations. <b>Atendi Visits</b> would require visits to be recorded, and a report to be written before leaving the premise.`,
  usecase_spa_desc2: `<b>Atendi Forms</b> can be used to create custom ordering forms to suit your needs. Or, create customer survey, return products, and others.`,
  usecase_spa_desc3: `Both <b>Atendi Integration</b> and <b>Atendi Forms</b> can be used to create a <b>micro app</b>. So during a customer visit, the sales person need to submit an order that would go to your ERP? Can be done. With Atendi integration, forms, and perhaps a little programming - you can have your own app on a mobile.`,
  usecase_spa_thumbnail: "Sales Team",
  usecase_spa_thumbnail_desc: `Get your sales team to be more productive by tracking their visits to customers' premises.`,

  usecase_manufacture_title:
    "<span>Contactless</span> attendance taking with Atendi",
  usecase_manufacture_desc1: `Schedule large group of employees, manage their shifts, and track their attendance. Monthly timesheet reporting as Excel spreadsheet.`,
  usecase_manufacture_desc2: `Track and approve leave via mobile. Set government compliant leave policy and public holidays. With our <b>Atendi Vibe</b> feature, you can quickly glance through how overall attendance perfomance on that day.`,
  usecase_manufacture_desc3: `Create specialized forms with <b>Atendi Forms</b> to replace paper. Use <b>Atendi Bulletins</b> to disseminate important information to all or group of employees.`,
  usecase_manufacture_thumbnail: "Factories",
  usecase_manufacture_thumbnail_desc:
    "Replace your clocking machine or fingerprint attendance. Cost efficient, and contactless.",

  usecase_remote_title: "Atendi for <span>Remote Worker</span>",
  usecase_remote_desc1: `Schedule your remote workers according to your company's needs. Should the remote has to be in specific locations or anywhere? Should the work hour is fixed, flexible or even anytime? <b>Atendi</b> can handle those. Timesheet can be generated as Excel Spreadsheet.`,
  usecase_remote_desc2: `Keep important information disseminated across remote workers with <b>Atendi Bulletins and News</b>. Keep everybody in the same page. No more missing WhatsApp text or email.`,
  usecase_remote_desc3: `Should you need to track remote employees locations during work hours, <b>Atendi Ping</b> feature can be utilized. Ping is designed to work only during work hours to ensure employees' privacy outside work hours.`,
  usecase_remote_thumbnail: "Remote Worker",
  usecase_remote_thumbnail_desc:
    "Working from home? Or anywhere remotely? Fixed schedule or anytime? We can handle that.",
};

const freemium = {
  choose_your_plan: "<span>Simple</span> pricing scheme",
};

export default {
  ...home,
  ...footer,
  ...header,
  ...benefits,
  ...features,
  ...contactus,
  ...pricing,
  ...usecase,
  ...freemium,
};
